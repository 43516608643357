import { GripperBarHorizontalIcon } from '@fluentui/react-icons-mdl2'
import { Box, Button, TrashCanIcon } from '@fluentui/react-northstar'
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Column, Row, useTable } from 'react-table'

import { Dropdown } from '../../components/Dropdown'
import { DragItem } from '../../draggableRow'
import { NotificationTicketCustomField } from './NotificationSettingDialog'
import styles from './NotificationTicketCustomFieldList.module.css'

interface Option {
  value: string
  sequence: number
}

const dndItemType = 'optionRow'

const DraggableRow = ({
  row,
  index,
  moveRow,
  isLast,
}: {
  row: Row<Option>
  index: number
  moveRow: (i: number, j: number) => void
  isLast: boolean
}) => {
  const dropRef = useRef<HTMLTableRowElement>(null)
  const dragRef = useRef(null)
  const [, drop] = useDrop({
    accept: dndItemType,
    hover(item: DragItem) {
      if (!isLast) {
        moveRow(item.index, index)
        item.index = index
      }
    },
  })

  const [, drag, preview] = useDrag({
    type: dndItemType,
    item: { type: dndItemType, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !isLast,
  })

  preview(drop(dropRef))
  drag(dragRef)

  return (
    <tr ref={dropRef} className={styles.tr}>
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            key={cell.getCellProps().key}
            className={styles.td}
          >
            <div className={styles.iconcontainer}>
              <span ref={dragRef}>
                {cell.value ? (
                  <GripperBarHorizontalIcon className={styles.icon} />
                ) : (
                  <GripperBarHorizontalIcon className={styles.disabledIcon} />
                )}
              </span>
            </div>
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

export const NotificationTicketCustomFieldList: FC<{
  customFieldDropdownOptions: number[]
  setCustomFieldDropdownOptions: Dispatch<SetStateAction<number[]>>
  selectedDropdownItems: NotificationTicketCustomField[]
}> = ({
  customFieldDropdownOptions,
  setCustomFieldDropdownOptions,
  selectedDropdownItems,
}) => {
  const [selectedItems, setSelectedItems] = useState(
    customFieldDropdownOptions.map((v) => v)
  )
  useEffect(() => {
    setSelectedItems(customFieldDropdownOptions.map((v) => v))
  }, [customFieldDropdownOptions])

  const columns: Column<Option>[] = useMemo(
    () => [
      {
        accessor: 'value',
        Cell: ({ cell }) => {
          return (
            <>
              <Dropdown
                className={styles.newButton}
                fluid
                placeholder={'選択してください'}
                items={selectedDropdownItems
                  .filter(
                    (v) =>
                      !selectedItems.includes(v.ticketCustomFieldId) ||
                      v.ticketCustomFieldId ===
                        (customFieldDropdownOptions[cell.row.index] !==
                        undefined
                          ? customFieldDropdownOptions[cell.row.index]
                          : 0)
                  )
                  .map((v) => {
                    return {
                      header: v.ticketCustomFieldName,
                      value: v.ticketCustomFieldId.toString(),
                    }
                  })}
                value={[
                  customFieldDropdownOptions[cell.row.index] !== undefined
                    ? customFieldDropdownOptions[cell.row.index].toString()
                    : '',
                ]}
                onValueChange={(values) => {
                  if (cell.row.index === customFieldDropdownOptions.length) {
                    const newOptions: number[] = [
                      ...customFieldDropdownOptions,
                      Number(values[0]),
                    ]
                    setCustomFieldDropdownOptions(newOptions)
                  } else {
                    const newOptions: number[] = [
                      ...customFieldDropdownOptions.slice(0, cell.row.index),
                      Number(values[0]),
                      ...customFieldDropdownOptions.slice(cell.row.index + 1),
                    ]
                    setCustomFieldDropdownOptions(newOptions)
                  }
                  setSelectedItems([...selectedItems, Number(values[0])])
                }}
              />
              {cell.value && (
                <Button
                  icon={<TrashCanIcon />}
                  text
                  iconOnly
                  title="Delete"
                  onClick={() => {
                    setCustomFieldDropdownOptions(
                      customFieldDropdownOptions.filter(
                        (_, i) => i !== cell.row.original.sequence
                      )
                    )
                    setSelectedItems(
                      selectedItems.filter(
                        (_, i) => i === cell.row.original.sequence
                      )
                    )
                  }}
                  className={styles.deleteButton}
                />
              )}
            </>
          )
        },
      },
    ],
    [
      customFieldDropdownOptions,
      setCustomFieldDropdownOptions,
      selectedDropdownItems,
      selectedItems,
    ]
  )
  const { getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable<Option>({
      columns,
      data: [
        ...customFieldDropdownOptions.map((option, i) => ({
          value: String(option),
          sequence: i,
        })),
        {
          value: '',
          sequence: customFieldDropdownOptions.length,
        },
      ],

      getRowId: (row) => row.sequence.toString(),
    })

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    if (hoverIndex === customFieldDropdownOptions.length - 1) {
      return
    }

    const optionsA = customFieldDropdownOptions[dragIndex]
    const optionsB = customFieldDropdownOptions[hoverIndex]
    customFieldDropdownOptions[dragIndex] = optionsB
    customFieldDropdownOptions[hoverIndex] = optionsA

    setCustomFieldDropdownOptions([...customFieldDropdownOptions])

    const selectedItemsA = selectedItems[dragIndex]
    const selectedItemsB = selectedItems[hoverIndex]
    selectedItems[dragIndex] = selectedItemsB
    selectedItems[hoverIndex] = selectedItemsA

    setSelectedItems([...selectedItems])
  }

  return (
    <>
      <Box className={styles.container}>
        <table {...getTableProps()} className={styles.table}>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <DraggableRow
                  index={index}
                  row={row}
                  moveRow={moveRow}
                  isLast={index === rows.length - 1}
                  {...row.getRowProps()}
                  key={row.getRowProps().key}
                />
              )
            })}
          </tbody>
        </table>
      </Box>
    </>
  )
}
